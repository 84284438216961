
import Vue from 'vue'
import EventView from '@/components/views/event/Event.vue'
import { GetDisplayEvent } from '@/api/displayAPI/EventAPI'
import { initEventData } from '@/util/Event'

export default Vue.extend<Data, Methods, Computed>({
  components: { EventView },
  data() {
    return {
      timer: null,
      info: initEventData,
      eventStatus: true,
    }
  },
  async created() {
    try {
      const { eventNo } = this.$route.query
      if (eventNo) {
        const { data } = await GetDisplayEvent({ eventNo: Number(eventNo) })
        if (!data.eventNo) return this.$router.push('/')
        this.info = data
        this.eventStatus = !this.$moment().isAfter(this.info.endDt)
        if (this.eventStatus) {
          this.onTimer()
        }
      }
    } catch (e) {
      console.log('event API error', e)
    }
  },
  beforeDestroy() {
    this.clearTimer()
  },
  methods: {
    onTimer() {
      this.timer = setInterval(() => {
        this.eventStatus = !this.$moment().isAfter(this.info.endDt)
      }, 1000)
    },
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = null
    },
  },
})

interface Data {
  info: EventData
  timer: number | null
  eventStatus: boolean
}
interface Methods {
  onTimer(): void
  clearTimer(): void
}
interface Computed {}
