var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative"},[_c('div',{staticClass:"content-area fixed top-0 fill-width z-index-1 white d-flex align-center",staticStyle:{"height":"44px"}},[_c('div',{staticClass:"d-flex align-center justify-space-between fill-width px-8"},[_c('v-icon',{attrs:{"small":""},on:{"click":_vm.handleBack}},[_vm._v(" $vuetify.icons.prevIcon ")]),_c('span',{staticClass:"one-line-string rixgo-extrabold align-center gray900--text font-g16-24"},[_vm._v(_vm._s(_vm.info.title))]),_c('v-icon',{on:{"click":function($event){_vm.share.show = true}}},[_vm._v("$vuetify.icons.shareIcon")])],1)]),(!_vm.eventStatus)?_c('div',{staticClass:"fill-width pt-42 pb-20",staticStyle:{"background":"black"}},[_c('div',{staticClass:"white--text"},[_c('div',{staticClass:"d-flex justify-center font-g14-20 rixgo-bold pb-2"},[_vm._v(" 종료된 이벤트입니다. ")]),(_vm.info.endDetailText !== '')?_c('div',{staticClass:"d-flex justify-center font-g12-18 rixgo-regular"},[_vm._v(" "+_vm._s(_vm.info.endDetailText)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex justify-center font-g12-18 rixgo-regular"},[_vm._v(" 당첨자는 발표일에 공지사항 게시판에서 확인하실 수 있습니다. ")])])]):_vm._e(),(_vm.info.eventImageList.length > 0)?_c('div',{class:`pb-29 ${_vm.eventStatus && 'pt-22'}`},[(_vm.info.eventType !== 'BALANCE')?_c('div',_vm._l((_vm.info.eventImageList.slice(
          0,
          _vm.info.eventImageList.length - 1
        )),function(item,index){return _c('v-img',{key:index,attrs:{"src":item.imageUrl},on:{"load":_vm.onLoadImage}},[_c('div',{staticClass:"fill-width d-flex justify-center"},[(item.buttonUseYN === 'Y')?_c('div',{staticClass:"width-80 fill-height d-flex justify-center"},_vm._l((_vm.info.buttonList),function(item,index){return _c('div',{key:index,staticClass:"absolute",style:(`${
                index === 0
                  ? 'top:33.16%; width:66.22%; height:6.88%; opacity : 0'
                  : 'top: 60.77%;width: 66.22%;height: 6.88%; opacity : 0'
              }`),on:{"click":function($event){return _vm.handleClick(item, index, true)}}})}),0):_vm._e()])])}),1):_vm._e(),(_vm.info.eventType === 'BALANCE')?_c('div',_vm._l((_vm.info.eventImageList.slice(
          0,
          _vm.info.eventImageList.length - 1
        )),function(item,index){return _c('div',{key:index},[(_vm.info.eventImageList)?_c('div',[_c('v-img',{attrs:{"src":item.imageUrl},on:{"load":_vm.onLoadImage}}),(item.buttonInfo)?_c('v-img',{attrs:{"src":item.buttonInfo.imageUrl},on:{"click":function($event){return _vm.goToLink(item.buttonInfo.link, index)}}}):_vm._e()],1):_vm._e()])}),0):_vm._e(),(_vm.info.appDownload)?_c('v-img',{attrs:{"src":_vm.info.appDownload.imageUrl},on:{"load":_vm.onLoadImage}},[_c('div',{staticClass:"fill-width d-flex justify-center"},[_c('div',{staticClass:"width-80 fill-height d-flex justify-center absolute",staticStyle:{"top":"58.9%","width":"66.22%","height":"21.5%","opacity":"0"},on:{"click":function($event){return _vm.goToAppDownloadPage(_vm.info.appDownload.link)}}})])]):_vm._e(),_c('v-img',{attrs:{"src":_vm.info.eventImageList[_vm.info.eventImageList.length - 1].imageUrl},on:{"load":_vm.onLoadImage}})],1):_vm._e(),(_vm.info.buttonList.length > 0)?_c('div',{staticClass:"content-area d-flex justify-center fixed fill-width z-index-1",staticStyle:{"bottom":"75px"}},_vm._l((_vm.info.buttonList),function(item,index){return _c('div',{key:index,staticClass:"width-50 buttonHeight d-flex align-center justify-center font-g13-16 rixgo-bold",style:(`background-color:${item.backgroundColor}; color: ${item.color}`),on:{"click":function($event){return _vm.handleClick(item, index, false)}}},[_vm._v(" "+_vm._s(item.text)+" ")])}),0):_vm._e(),_c('Alert',{attrs:{"show":_vm.alert.show,"content":_vm.alert.content},on:{"onClose":_vm.onClose}}),_c('Sharing',{attrs:{"store":_vm.info.shareInfo.title,"content":_vm.info.shareInfo.description,"url":_vm.shareUrl,"show":_vm.share.show,"shareItem":{
      ..._vm.info.shareInfo,
      url: _vm.shareUrl,
    },"sheetTitle":"이벤트"},on:{"onClose":function($event){_vm.share.show = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }