
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import Alert from '@/components/common/dialogs/Alert.vue'
import Sharing from '@/components/common/dialogs/Sharing.vue'
import { PropType } from 'vue'

import { initEventData } from '@/util/Event'

const DEFAULT_LOGIN_ALERT_TEXT =
  '이미 셀룩의 회원이시네요. 바로 STEP2로 넘어가시면 됩니다.'
const DEFAULT_EVENT_ALERT_TEXT =
  '회원가입 또는 로그인이 필요합니다. Step1을 먼저 확인해 주세요.'

const EVENT_END_TEXT = '종료된 이벤트입니다.'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    Alert,
    Sharing,
  },
  props: {
    info: {
      type: Object as PropType<EventData>,
      required: true,
    },
    eventStatus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      alert: {
        show: false,
        content: '',
        eventUrl: '',
      },
      share: {
        show: false,
      },
      imageLoadCount: 0,
    }
  },
  computed: {
    ...mapState('DeviceStore', ['deviceInfo']),
    shareUrl() {
      return process.env.VUE_APP_CELLOOK_URL + '/?eventNo=' + this.info.eventNo
    },
  },
  methods: {
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    handleBack() {
      this.$tracking('event_talk')

      // todo 히스토리 검사에는 한계가 명확. 정확하지 않음
      if (history.length <= 1) {
        return this.$router.push('/')
      }

      this.$router.back()
    },
    handleClick(buttonInfo, index, imageButton) {
      const { eventUrl, alertText } = buttonInfo
      const { eventNo } = this.info
      if (imageButton) {
        this.$tracking(`event_${eventNo}_${this.$numbering(index + 3)}`)
      } else {
        this.$tracking(`event_${eventNo}_btn_${this.$numbering(index + 1)}`)
      }

      if (!this.eventStatus && index) {
        this.alert = {
          show: true,
          content: EVENT_END_TEXT,
          eventUrl: '',
        }
        return
      }

      // 로그인 체크를 하는 페이지의 경우
      if (this.info.loginCheckYN !== 'Y') {
        this.pageHandler(eventUrl)
        return
      }

      //로그인이 되어 있을 때
      if (this.isLogined) {
        if (eventUrl === '/login') {
          this.alert = {
            show: true,
            content: imageButton ? DEFAULT_LOGIN_ALERT_TEXT : alertText,
            eventUrl: eventUrl,
          }
          return
        }
        this.pageHandler(eventUrl)
        return
      }

      // 로그인이 되어 있지 않을 때
      if (!this.isLogined) {
        if (eventUrl === '/login') {
          return this.fetchLoginDialog({ yn: true })
        }
        this.alert = {
          show: true,
          content: imageButton ? DEFAULT_EVENT_ALERT_TEXT : alertText,
          eventUrl: '',
        }
        return
      }
    },
    pageHandler(url) {
      const { title } = this.info
      const payload = {
        url,
        title,
        isGoneBottomView: true,
      }

      this.$rendingOutsidePage(this.$device, payload)
      this.saveScrollLocation()
    },
    goToAppDownloadPage(downLoadPageUrl) {
      this.$tracking(`event_${this.info.eventNo}_btn_appdownload`)
      this.pageHandler(downLoadPageUrl)
    },
    goToLink(link: string, index: number) {
      this.$tracking(
        `event_${this.info.eventNo}_landing_${this.$numbering(index + 1)}`
      )

      const url = new URL(link)

      if (url.origin === 'https://cellook.kr') {
        this.$router.push(url.pathname + url.search)
      } else {
        this.pageHandler(link)
      }
      this.saveScrollLocation()
    },
    onClose() {
      this.alert.show = false

      if (
        this.info?.eventType === 'BALANCE' &&
        this.alert.eventUrl !== '/login'
      ) {
        sessionStorage.setItem('eventHistory', this.$route.fullPath)
        return this.fetchLoginDialog({ yn: true })
      }
    },
    saveScrollLocation() {
      localStorage.setItem(
        `eventScrollLocation${this.info.eventNo}`,
        `${this.$getAppHtml.scrollTop}`
      )
    },
    restoreScrollLocation() {
      Vue.nextTick(() => {
        const top =
          Number(
            localStorage.getItem(`eventScrollLocation${this.info.eventNo}`)
          ) ?? 0
        this.$getAppHtml.scrollTo({ top: top })
        localStorage.removeItem(`eventScrollLocation${this.info.eventNo}`)
      })
    },
    onLoadImage() {
      this.imageLoadCount++

      const basisImageCount =
        this.info.eventImageList.length +
        (this.info.appDownload?.imageUrl ? 1 : 0)

      if (basisImageCount === this.imageLoadCount) {
        this.restoreScrollLocation()
      }
    },
  },
})

interface Data {
  alert: {
    show: boolean
    content: string
    eventUrl: string
  }
  share: {
    show: boolean
  }
  imageLoadCount: number
}
interface Methods {
  handleBack(): void
  handleClick(
    buttonInfo: {
      text: string
      eventUrl: string
      alertText: string
    },
    index: number,
    defaultTextYn?: boolean
  ): void
  pageHandler(link: string): void
  fetchLoginDialog(payload: { yn: boolean }): void
  goToAppDownloadPage(link: string): void
  goToLink(link: string, index: number): void
  onClose(): void
  saveScrollLocation(): void
  restoreScrollLocation(): void
  onLoadImage(): void
}
interface Computed {
  deviceInfo: DeviceInfo
  shareUrl: string
}
interface Props {
  info: EventData
  eventStatus: boolean
}
