export const initEventData: EventData = {
  buttonList: [
    {
      text: '',
      alertText: '',
      eventUrl: '',
      color: '',
      backgroundColor: '',
    },
  ],
  endDetailText: '',
  endDt: '',
  eventImageList: [
    {
      imageUrl: '',
      buttonUseYN: 'N',
      buttonInfo: {
        imageUrl: '',
        link: '',
      },
    },
  ],
  eventNo: 0,
  eventType: null,
  loginCheckYN: '',
  shareInfo: { description: '', imageUrl: '', title: '' },
  title: '',
}
